import React from 'react';
import Button from 'react-bootstrap/Button'
import { NavLink } from 'react-router-dom';

const DiscoverButton = () => (
    <div className="discover-button-position">
      <Button size="lg" className="button discover-button btn-danger text-nowrap" to={"/about"}>
        <NavLink to="/about">Discover more</NavLink>
      </Button>
  </div>
  );
  
  export default DiscoverButton;


