import React, {Fragment} from "react";
import AccordionAbout from "../components/AccordionAbout";


const About = () => {
  return (
    <Fragment>
      <h1>About us</h1>
      <p> In non-profit organisation Barcelona Growing Talent we are committed to the development of projects
         for the integration of young students into the world of work.
         We seek to collaborate with companies that,
          in addition to contributing to society by exercising their mission and values,
           decide to go one step further by committing themselves to social commitment.</p>
      <p>
        Barcelona Growing Talent offers a network alliance to set the integration of students into the workplace,
         giving them the opportunity to have a positive influence on the generations that make up the future
          of our society. The student period is the most decisive period in terms of employment.
           Good advice, guided work experience or confidence can make the difference between a frustrated working life
            or a successful career where one has been able to fulfil their potential.
      </p>

      <AccordionAbout/>

      <p>At Barcelona Growing Talent we have more than 5 years of experience helping students find jobs and guiding them through the personal and work-related difficulties they face.</p>

      <p>Please contact us if you are interested in our project and are able to discuss the possibility of our service offering.</p>
    </Fragment>

  );
}

export default About;