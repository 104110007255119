// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCbjaixNWmtSt34e8L8KXKKm1wlb2_MOIU",
  authDomain: "bgt-web-app-v01.firebaseapp.com",
  databaseURL: "https://bgt-web-app-v01-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "bgt-web-app-v01",
  storageBucket: "bgt-web-app-v01.appspot.com",
  messagingSenderId: "534722362771",
  appId: "1:534722362771:web:e9735c30e1f6850feb3c66"
};

const bgtapp = initializeApp(firebaseConfig);

export default bgtapp;
