import React from 'react';
import Button from 'react-bootstrap/Button'

export const ContactButton = () => (
    <div>
      <Button size="lg" className="button basic-button">
      Contact us
      </Button>
  </div>
  );

  export default ContactButton;