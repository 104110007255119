import React from 'react';
import Container from 'react-bootstrap/Container';

const ServicesStat = () => {
  return (
    <Container className="container line-gray">
        <div className="services-grid">
          <div className="services">
            <h2 className="visually-hidden">Mission and Service</h2>
            <p className="slogan">Let's grow more<br/>
              TALENTS!</p>
            <p className="services">We want to inspire companies to hire candidates without a lot of experience but with a great desire to work.</p>
            <p className="text-uppercase services-orders">Helping to young peaople finding fare paid job as first experience after university:</p>
            <ul className="services-list clear-list">
              <li className="services-item">Helping candidats with the CVs</li>
              <li className="services-item">Referal program</li>
              <li className="services-item">Prepearation for the interview</li>
            </ul>
          </div>
          <div className="statistics visually-hidden">
            <img src="img/bgtillustration.jpg" width="360" height="208" alt="Illustration BGT"/>
            <table className="statistics-content">
              <caption>from 2022</caption>
              <tbody>
                <tr>
                  <th>146<sup>%</sup></th>
                  <th>100<sup>%</sup></th>
                  <th>50<sup>%</sup></th>
                </tr>
                <tr>
                  <td>Desire to help</td>
                  <td>Paying attention to personality</td>
                  <td>Making fun</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Container>  
  );

}
export default ServicesStat;