import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Partners from './pages/Partners';
import Contacts from './pages/Contacts';
import NavigationMenu from './components/NavigationMenu';
import FooterBgt from './components/FooterBgt';
import LegalNotice from './pages/LegalNotice';

function App() {
  return (
      <BrowserRouter>
        <NavigationMenu src={NavigationMenu} />
        <div className="container pt4">
          <Routes>
            <Route path={'/'} element={<Home />}/>
            <Route path={'/about'} element={<About />}/>
            <Route path={'/partners'} element={<Partners />}/>
            <Route path={'/contacts'} element={<Contacts />}/>
            <Route path={'/legalandprivacy'} element={<LegalNotice />}/>
          </Routes>
        </div>
        <FooterBgt />
      </BrowserRouter>
  );
}

export default App;
