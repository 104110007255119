import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { addDoc, collection } from 'firebase/firestore';
import bgtapp from './FirebaseConfig';
import { getFirestore } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { Link } from 'react-router-dom';

function ContactModal() {

  const db = getFirestore(bgtapp);
  const fileStorage = getStorage(bgtapp);


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [message,setMessage] = useState(null);
  const [file, setFile] = useState(null);
  const [agreement, setAgreement] = useState(false);

  const userCollectionRef = collection (db, "contactdata");

  
  const cvRef = ref(fileStorage, `CVs/${name}`);
  

  const handleInputChange = (e) => {
    const {id , value} = e.target;
    if(id === "name"){
        setName(value);
    }
    if(id === "email"){
        setEmail(value);
    }
    if(id === "message"){
        setMessage(value);
    }
    if(id === "agreeCheckbox"){
      setAgreement(value);
    }
  }

  function handleFileChange (e) {
    setFile(e.target.files[0])
  }

  const handleSubmit  = () => {
    if (!name || !email || !message ||!file || !agreement) {
      alert("Please fill in all fields of the form!");
    } else {
      addDoc(userCollectionRef, {
        name: name,
        email:email,
        message: message
      });
      uploadBytes(cvRef, file).then((snapshot) => {
        console.log('Uploaded a file!');
      }).then(() => {
      if (!alert("Your message has been successfully sent!"))document.location="https://barcelonagrowingtalent.org/"
      })
    }

  }


  return (
    <>
      <Button size="lg" className="button btn-danger text-nowrap" onClick={handleShow}>
        Contact us
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header className="modal-header" closeButton >
          <Modal.Title className="modal-title">Contact us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="contactForm">
            <div className="container text-center">
              <div className="row">
                <div className="col-sm-5 col-md-6">
                  <Form.Label className="form-label" />
                  <FloatingLabel label="Email address" className="mb-3">
                    <Form.Control type="email" placeholder="name@example.com" name="email" id="email" autoFocus onChange = {(e) => handleInputChange(e)} required/>
                  </FloatingLabel>
                </div>
                <div className="col-sm-5 offset-sm-2 col-md-6 offset-md-0">
                  <Form.Label className="form-label" />
                  <FloatingLabel label="Name" className="mb-3" required>
                    <Form.Control type="name" placeholder="Name" name="name" id="name" onChange = {(e) => handleInputChange(e)}/>
                  </FloatingLabel>
                </div>
              </div>
            </div>
            <div>
              <Form.Label className="form-label" />
              <FloatingLabel label="Your message">
                <Form.Control as="textarea" name="message" id="message" placeholder="Leave your message here" onChange = {(e) => handleInputChange(e)} style={{ height: '250px' }}/>
              </FloatingLabel>
            </div>
            <div className="file-upload">
              <Form.Control type="file" onChange= {(e) => handleFileChange(e)} label="Chosse file" id="fileUpload"/>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="container text-center">
                
              <div className="row">
                <div className="col-md-4">
                  <Form.Group>
                    <Form.Check id="agreeCheckbox" name="terms" label="Agree terms and conditions" onChange={(e) => handleInputChange(e)}  feedbackTooltip/>
                    <Form.Text className="text-muted text-agreement">
                      <p>by submiting this form you're confirming<br/> <Link to={'/legalandprivacy'}>agreement about data collecting and processing.</Link></p>
                    </Form.Text>
                  </Form.Group>
                </div>
                <div className="col-md-4 offset-md-4">
                  <Button size="lg" className="button btn-danger" onClick={handleClose}>
                    Close
                  </Button>
                  <Button size="lg" className="button btn-danger" onClick={()=>handleSubmit()}>
                    Send
                  </Button>
                </div>
            </div>
          
          </div>
        </Modal.Footer>
      </Modal>
    </>

    
  );
}

export default ContactModal;