import React, {Fragment} from "react";
import  ContactModal  from "../components/Modal";
import {Link} from "react-router-dom"
import {BiBuildings} from 'react-icons/bi'
import {RiPhoneLine} from 'react-icons/ri'
import {BiMailSend} from 'react-icons/bi'
import HomeCarousel from "../components/HomeCarousel";

const Contacts = () => {
  return (
    <Fragment>
      
      <h1 hidden>Contacts page</h1>
      <div className="carousel-position">
        <HomeCarousel />
      </div>
      <div className="row p-4 mb-4 bg-dark text-light rounded-3 mapBgt">
          <div className="col-md-4 bg-white text-dark text-center rounded-3">
              <h5 className="contact-info"><BiBuildings/>Carrer de Balmes<br/>
               92, Barcelona</h5>
              <Link className="contacts-tel" to="tel:+34935324023"><RiPhoneLine/>+34 93-532-40-23</Link>
              <br/>
              <Link to="email:barcelonagrowingtalent@gmail.com"><BiMailSend/>barcelonagrowingtalent@gmail.com</Link>
          </div>
          <div className=" col-md-4 container mapContactButton">
            <h5>Please contact us if you would like to interact with us <br /> or just want to know more about us.</h5>
            <ContactModal />
          </div>
      </div>
      
    </Fragment>
  );
}

export default Contacts;