import DiscoverButton from './DiscoverButton';
import Card from 'react-bootstrap/Card';
import Desire  from "../img/crosswalk.jpg"

function AdvantageOne() {
  return (
    <Card className="advantageCard container-fluid">
      <Card.Img variant="top" src={Desire} className="img-fluid" style={{maxWidth: "100%", height: "auto"}}/>
      <Card.Body>
        <Card.Title>DESIRE TO HELP IS OUR MAIN INTENTION</Card.Title>
        <Card.Text>
        We understand the importance of mutual assistance,
         and appreciate the desire to develop.
        </Card.Text>
        <DiscoverButton />
      </Card.Body>
    </Card>
  );
}

export default AdvantageOne;