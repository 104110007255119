import React from 'react';
import DiscoverButton from './DiscoverButton';
import Card from 'react-bootstrap/Card';
import PersonalGrowing from "../img/personalgrowing.jpg"


function AdvantageThree() {
  return (
    <Card className="advantageCard container-fluid">
      <Card.Img variant="top" src={PersonalGrowing} className="img-fluid" style={{maxWidth: "100%", height: "auto"}}/>
      <Card.Body>
        <Card.Title>HOW WE ARE HELPING STUDENTS</Card.Title>
        <Card.Text>
        What we are trying to do for helping young people in their careers and lifes.
        </Card.Text>
        <DiscoverButton />
      </Card.Body>
    </Card>
  );
}

export default AdvantageThree;