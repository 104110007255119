import React, {Fragment} from "react";


const LegalNotice = () => {
  return (
    <Fragment>
      <h1>Legal Notice and privacy policy</h1>
      <p>Legal Notice: In accordance with the provisions of Law 34/2002 on Information Society Services and Electronic Commerce,
        as well as Organic Law 3/2018 of 5 December on the Protection of Personal Data and Guarantee of Digital Rights, we inform you that your data such as:</p>
      <ol>
        <li>Email address.</li>
        <li>Personal information about name, date of birth and documents. </li>
        <li>Personal data about your city of residence, current or completed studies,
        current or completed employment, etc.</li>
      </ol>
        <p>Are included in our databases, used and processed in order to
         ensure, perform and improve the services provided by Barcelona Growing Talent.
          You may exercise your right to access, rectify and, if necessary, cancel your personal data at any time by writing us an email to barcelonagrowingtalent@gmail.com and indicating the operation to be performed.</p>

        <p>Barcelona Growing Talent is a non-profit association created to help students find their true potential through
         internships and professional mentoring. It benefits from the extensive experience that Círculo Inmobiliario Meridiana
          has gained over the years, as well as its databases to provide the best services.</p>
    </Fragment>

  );
}

export default LegalNotice;





