import React from 'react';
import { Link } from 'react-router-dom';

const FooterBgt = () => {
  return (  
    <footer className="main-footer">
      <div className="row text-center">
        
          
            
                <div className="col footer-social">                  
                  <ul className="social-list clear-list">
                    <li className="social-space">
                    </li>
                    <li className="social-space-one">
                    </li>
              
                    <li>
                      <a className="social-button twit" href="https://www.linkedin.com/company/barcelonagrowingtalent/about/" aria-label="Twitter">
                        <svg aria-hidden="true" width="35" height="35" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M 83.349 0 H 6.651 C 2.978 0 0 2.887 0 6.447 v 77.106 C 0 87.114 2.978 90 6.651 90 h 76.698 C 87.022 90 90 87.114 90 83.553 V 6.447 C 90 2.887 87.022 0 83.349 0 Z M 27.282 75.339 H 13.688 v -40.64 h 13.594 V 75.339 Z M 20.485 29.151 h -0.088 c -4.562 0 -7.512 -3.121 -7.512 -7.021 c 0 -3.988 3.04 -7.022 7.69 -7.022 c 4.651 0 7.513 3.034 7.601 7.022 C 28.176 26.03 25.225 29.151 20.485 29.151 Z M 76.299 75.339 H 62.707 V 53.598 c 0 -5.463 -1.968 -9.19 -6.887 -9.19 c -3.756 0 -5.992 2.513 -6.975 4.94 c -0.359 0.868 -0.447 2.081 -0.447 3.296 v 22.695 H 34.804 c 0 0 0.178 -36.826 0 -40.64 h 13.594 v 5.756 c 1.806 -2.769 5.036 -6.709 12.251 -6.709 c 8.944 0 15.65 5.808 15.65 18.291 V 75.339 Z" fill="white"/>
                        </svg>
                      </a>
                    </li>
                    <li> 
                      <a className="social-button insta" href="https://www.instagram.com/barcelonagrowingtalent/" aria-label="Instagram">
                        <svg aria-hidden="true" width="35" height="35" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M3 0H18C19.65 0 21 1.35 21 3V18C21 19.65 19.65 21 18 21H3C1.35 21 0 19.65 0 18V3C0 1.35 1.35 0 3 0ZM14 10.5C14 8.57 12.43 7 10.5 7C8.57 7 7 8.57 7 10.5C7 12.43 8.57 14 10.5 14C12.43 14 14 12.43 14 10.5ZM3 18V9H4.181C4.0626 9.49127 4.00186 9.99467 4 10.5C4 14.0899 6.91015 17 10.5 17C14.0899 17 17 14.0899 17 10.5C16.9986 9.99464 16.9379 9.49118 16.819 9H18V18H3ZM14 7H18V3H14V7Z" fill="white"/>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              <div className="col cta">
                  <h3 className="cta-text slogan">Follow us in social media!</h3>
              </div>
            
            
          
          <div className="col">
            <div className="cta registrationInfo">
              <Link className="cta-text" to={'/legalandprivacy'} >Legal Information and privacy policy<br />
              of "Barcelona Growing Talent" Website</Link>
            </div>
          </div>
          
        
      </div>
    </footer>
  );
}

export default FooterBgt;