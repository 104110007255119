import React, {Fragment} from "react";
import  DiscoverButton  from "../components/DiscoverButton";
import  ContactButton  from "../components/ContactButton";


const Partners = () => {
  return (
    <Fragment>
      <h1>Partners page</h1>
    <div class="p-4 mb-4 bg-dark text-light rounded-3 sliderItemOne">
      <div class="container-fluid py-5">
        <h1 class="display-4 fw-bold">Let's collaborate</h1>
        <h3 class="col-md-8 fs-4">It's important for us to build up a network of companies that<br/>
                  seek to contribute to social commitment</h3>
        <ContactButton />
      </div>
    </div>
    <div class="p-5 mb-4 bg-dark text-light rounded-3 sliderItemTwo">
      <div class="container-fluid py-5">
        <h1 class="display-5 fw-bold">About Page</h1>
        <h3 class="col-md-8 fs-4">This is a Testing page for legal info and About page</h3>
        <DiscoverButton />
      </div>
    </div>
    <div class="p-5 mb-4 bg-light rounded-3 text-light sliderItemThree">
      <div class="container-fluid py-5">
        <h1 class="display-5 fw-bold">About Page</h1>
        <h3 class="col-md-8 fs-4">This is a Testing page for legal info and About page</h3>
        <DiscoverButton />
      </div>
    </div>
    </Fragment>

  );
}

export default Partners;