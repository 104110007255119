import React from 'react';
import DiscoverButton from './DiscoverButton';
import Card from 'react-bootstrap/Card';
import Learning from "../img/learning.jpg"

function AdvantageTwo() {
  return (
    <Card className="advantageCard container-fluid">
      <Card.Img variant="top" src={Learning} className="img-fluid" style={{maxWidth: "100%", height: "auto"}}/>
      <Card.Body>
        <Card.Title>PERSONAL APPROACH IS OUR FORTE</Card.Title>
        <Card.Text>
          We are attentive to everyone who contacts and
          use an individualised approach to each person and case.
        </Card.Text>
        <DiscoverButton />
      </Card.Body>
    </Card>
  );
}

export default AdvantageTwo;