import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { addDoc, collection } from 'firebase/firestore';
import bgtapp from './FirebaseConfig'
import { getFirestore } from "firebase/firestore";

function ModalPartners() {
  
  const db = getFirestore(bgtapp);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [company, setCompany] = useState(null)
  const [message,setMessage] = useState(null);
  const [agreement, setAgreement] = useState(false)

  const partnerCollectionRef = collection (db, "PartnershipContactdata")

  const collectInputChange = (e) => {
    const {id , value} = e.target;
    if(id === "name"){
        setName(value);
    }
    if(id === "email"){
        setEmail(value);
    }
    if(id === "company"){
        setCompany(value);
    }
    if(id === "message"){
        setMessage(value);
    }
    if(id === "agreeCheckbox"){
      setAgreement(value);
    }
  }

  const handleSubmit  = () => {
    if (!name || !email || !message || !agreement) {
      alert("Please provide value in each input field");
    } else {
      addDoc(partnerCollectionRef, {
        name: name,
        email:email,
        company:company,
        message: message
      }).then(() => {
        if (!alert("form submitted successfully!!!"))document.location="https://barcelona-growing-talent-v1.web.app/"
      })
    }

  }


  return (
    <>
      <Button size="lg" className="button btn-danger" onClick={handleShow}>
        Become our partner
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header className="modal-header" closeButton >
          <Modal.Title className="modal-title">Become our partner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="contactForm">
            <div className="container text-center">
              <div className="row">
                <div className="col-sm-5 col-md-6">
                  <Form.Label className="form-label" />
                  <FloatingLabel label="Email address" className="mb-3">
                    <Form.Control type="email" placeholder="name@example.com" name="email" id="email" autoFocus onChange = {(e) => collectInputChange(e)} required/>
                  </FloatingLabel>
                </div>
                <div className="col-sm-5 offset-sm-2 col-md-6 offset-md-0">
                  <Form.Label className="form-label" />
                  <FloatingLabel label="Name" className="mb-3" required>
                    <Form.Control type="name" placeholder="Name" name="name" id="name" onChange = {(e) => collectInputChange(e)}/>
                  </FloatingLabel>
                </div>
              </div>
            </div>
            <div className="">
              <Form.Label className="form-label" />
              <FloatingLabel label="Company or Person" className="mb-3" required>
                <Form.Control type="company" placeholder="Company or Person" name="company" id="company" onChange = {(e) => collectInputChange(e)}/>
              </FloatingLabel>
            </div>
            <div>
              <Form.Label className="form-label" />
              <FloatingLabel label="Your message">
                <Form.Control as="textarea" name="message" id="message" placeholder="Leave your message here" onChange = {(e) => collectInputChange(e)} style={{ height: '200px' }}/>
              </FloatingLabel>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="container text-center">
                <Form.Group className="col-sm-9">
                <Form.Check id="agreeCheckbox" name="terms" label="Agree to terms and conditions" onChange={(e) => collectInputChange(e)}  feedbackTooltip/>
                    <Form.Text className="text-muted">
                       We'll never share your email with anyone else.
                    </Form.Text>
                </Form.Group>
          </div>
          <Button size="lg" className="button" onClick={handleClose}>
            Close
          </Button>
          <Button size="lg" className="button" onClick={()=>handleSubmit()}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </>

    
  );
}

export default ModalPartners;