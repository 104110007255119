import Accordion from 'react-bootstrap/Accordion';

function AccordionAbout() {
  return (
    <Accordion defaultActiveKey="0" className="accordion-about">
      <Accordion.Item eventKey="0">
        <Accordion.Header><strong>History of establishment</strong></Accordion.Header>
        <Accordion.Body>
        <strong>Barcelona Growing Talent</strong> was created by “Círculo Inmobiliario Meridiana” (from now on Meridiana),
                 a real estate investment and consulting firm, established in Barcelona. The idea of BGT was born in 2014 with a clear horizon of helping society, and particularly students.
                  In 2017 we started to work with the High School “La Farga”, and after a successful experience of five years of finding summer jobs for the students,
                     we decided to open the association to every student of Barcelona.
                    <br/><br/>
                     The project has been successful to find the best win-win situation for the student and the company.
                      While making sure we appoint the right person to a fitting company, BGT benefits the long trajectory of Meridiana 
                      by taking advantage of the students' influence in different sectors of the entrepreneurial world.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header><strong>Mission and services</strong></Accordion.Header>
        <Accordion.Body>
        <strong>The mission of BGT is to help students find their full potential through a first job experience</strong> and professional coaching sessions. With that purpose we offer two main services: coaching sessions and a referral system for the best mutual matching of employers and students.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default AccordionAbout;