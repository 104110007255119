import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Bgtlogo from "../img/Bgtlogo.svg";
import {NavLink} from 'react-router-dom';
import ContactModal from './Modal';


const NavigationMenu = () => {
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="https://barcelonagrowingtalent.org/" className="nav-logo text-nowrap">
          <img alt="BGT logo" src={Bgtlogo} width="30" height="30" className="d-inline-block align-top" />{' '}
          Barcelona Growing Talent
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto main-navigation nav-contact">
          <NavLink className="nav-link text-nowrap"  aria-current="page" to="/" style={({isActive})=>({ borderBottom: isActive ? "var(--basic-orange) solid 2px": '', opacity: isActive ? 1 : "" })}>Home</NavLink>
            <NavLink className="nav-link text-nowrap visually-hidden" aria-current="page" to="/partners" style={({isActive})=>({ borderBottom: isActive ? "var(--basic-orange) solid 2px": '', opacity: isActive ? 1 : "" })}>Partners</NavLink>
            <NavLink className="nav-link text-nowrap" aria-current="page" to="/contacts" style={({isActive})=>({ borderBottom: isActive ? "var(--basic-orange) solid 2px": '', opacity: isActive ? 1 : "" })}>Contacts</NavLink>
            <NavLink className="nav-link text-nowrap" aria-current="page" to="/about" style={({isActive})=>({ borderBottom: isActive ? "var(--basic-orange) solid 2px": '', opacity: isActive ? 1 : "" })}>About us</NavLink>
            <div className="contact-button-nav">
              <ContactModal className="mobile-button-nav"/>
            </div>
            <NavDropdown title="Dropdown" className="visually-hidden" id="basic-nav-dropdown">
              <NavDropdown.Item to="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item to="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item to="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item to="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationMenu;

