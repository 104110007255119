import React from 'react';
import Container from 'react-bootstrap/Container';
import MeridianaLogo from '../img/partners/meridiana.png';
import ModalPartners from './ModalPartners';

const PartnersBlock = () => {
  return (
        <Container className="partners">
        <div className="container">
          <h2 className="visually-hidden">Our Partners</h2>
          <ul className="partners-list clear-list">
            <li className="partners-item">
              <a href="https://inversionmeridiana.com"><img src={MeridianaLogo} width="199" alt="Meridiana logo"/></a>
            </li>
            <li>
              <div className="container">
                <ModalPartners />
              </div>
            </li>
          </ul>  
        </div>  
      </Container> 
  );

}
export default PartnersBlock;