import React, {Fragment} from "react";
import HomeCarousel from "../components/HomeCarousel"
import AdvantageOne from "../components/AdvantageOne";
import AdvantageTwo from "../components/AdvantageTwo";
import AdvantageThree from "../components/AdvantageThree";
import ServicesStat from "../components/ServicesStat"
import PartnersBlock from '../components/PartnersBlock'

const Home = () => {
  return (
    <Fragment>
      <h1 hidden>Home page</h1>
      <div className="carousel-position">
        <HomeCarousel />
      </div>
      <ServicesStat />
      <div className="row align-items-start">
        <div className="row align-items-center contentPosition">
          <div className="col">
            <AdvantageOne />
          </div>
          <div className="col">
            <AdvantageTwo />
          </div>
          <div className="col">
            <AdvantageThree />
          </div>
        </div>
      </div>
      <PartnersBlock />
    </Fragment>

  )
}

export default Home;