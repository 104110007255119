import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import DiscoverButton from './DiscoverButton';
import ContactModal from './Modal';



function HomeCarousel () {
  return (
    <Carousel className="carousel-position">
      <Carousel.Item>
        <div className="container-fluid py-5 sliderItemOne rounded-3 carouselContent">
          
            <h1 className="fw-bold sliderContent">Let's collaborate</h1>
            <p className="sliderContent">It's important for us to build up a network of companies that<br/>
                seek to contribute to social commitment</p>
            <div className="row justify-content-md-center"> 
              <div className="col col-lg-2">
            </div>
            <div className="col-md-auto col-6">
              <ContactModal />
            </div>
            <div className="col col-lg-2">
              </div>
            </div>
            
        </div>
      </Carousel.Item>

      <Carousel.Item>
        <div className="container-fluid py-5 sliderItemTwo rounded-3 carouselContent">
          
            <h1 className="sliderContent">Our mission</h1>
            <p className="sliderContent">Our main goal is<br /> to integrate young students
            into the world of work</p>
          <div className="row justify-content-md-center"> 
            <div className="col col-lg-2">
            </div>
            <div className="col-md-auto col-6">
              <DiscoverButton />
            </div>
            <div className="col col-lg-2">
            </div>
          </div>
        
        </div>  
      </Carousel.Item>

    </Carousel>
  );
  }

export default HomeCarousel;